// src/components/LoginComponent.js
import React from 'react';
import { Box, Button, Grid, Typography, Paper } from '@mui/material';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import FacebookLogin from 'react-facebook-login';
import GoogleIcon from '@mui/icons-material/Google'; // Import Material-UI Google icon
import axios from 'axios';
import '../css/styles.css';
const LoginComponent = () => {
  const clientId = '1025142309416-pon5siunqku3hvfv6ukbbes0ks6ieepe.apps.googleusercontent.com';
  const responseFacebook = async (response) => {
    if (response.accessToken) {
      try {
        // Send the Facebook access token to the backend
        const res = await axios.post('https://broadwayoriginals.com/apis/loginwith/login_with_fb.php', {
          accessToken: response.accessToken,
        });
        const resdata = await res.json();
        if (resdata.ok) {
            const headers = resdata.headers;
            const customlog = headers.get('Login'); 
            const expiresAt = new Date(Date.now() + 3600000).toISOString();
            localStorage.setItem('user', JSON.stringify({ ...resdata, expiresAt, customlog }));
            window.location.href = "/dashboard";
        }
        
      } catch (error) {
        console.error('Login Error:', error);
      }
    } else {
      console.error('Facebook login failed.');
    }
  };
  const handleLoginSuccess = (tokenId) => {
    fetch('https://broadwayoriginals.com/apis/loginwith/login_with_google.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ token: tokenId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.ok) {
            const headers = data.headers;
            const customlog = headers.get('Login'); 
            const expiresAt = new Date(Date.now() + 3600000).toISOString();
            localStorage.setItem('user', JSON.stringify({ ...data, expiresAt, customlog }));
            window.location.href = "/dashboard";
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };
  const onGoogleSuccess = (response) => {
    handleLoginSuccess(response.credential);
  };

  const onGoogleError = () => {
    console.log('Google Login Failed');
  };

  const handleFacebookLogin = () => {
    // Trigger Facebook login manually here
    responseFacebook();
  };
  
  return (
    <GoogleOAuthProvider clientId={clientId}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box display="flex" flexDirection="column" alignItems="center" sx={{width: '100%'}}>
              <GoogleLogin
                onSuccess={onGoogleSuccess}
                onError={onGoogleError}
                render={(renderProps) => (
                  <Button
                    onClick={renderProps.onClick}
                    disabled={renderProps.disabled}
                    fullWidth
                    sx={{
                      minWidth: '100%',
                      height: 'auto',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderRadius: 1,
                      backgroundColor: '#4285F4', // Google color
                      color: '#fff',
                      fontWeight: 'bold',
                      '&:hover': {
                        backgroundColor: '#357ae8', // Darker shade on hover
                      },
                      transition: 'background-color 0.3s, transform 0.3s',
                    }}
                  >
                    <GoogleIcon sx={{ marginRight: 1 }} />
                    Login with Google
                  </Button>
                )}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="center">
                <FacebookLogin appId="542100411473056"  autoLoad={false}  fields="name,email,picture"  callback={responseFacebook} icon="fa-facebook" className='facebook-login' style={{padding: '7px !important', fontSize: '14px !important', textTransform: 'none !important'}}  />
            </Box>
          </Grid>
        </Grid>
    </GoogleOAuthProvider>
  );
};

export default LoginComponent;
