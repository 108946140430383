import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

export default function TitlebarBelowImageList(memberslist) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));
  const itemData = JSON.parse(memberslist.memberslist);
  return (
    <Box justifyContent="center" sx={{ padding: '70px', background: 'rgba(240, 244, 248, 0.8)' }}>
      <Typography variant="h5" sx={{ fontSize: '20px', color: 'black', fontWeight: 'bold', textAlign: 'center', marginBottom: '20px' }}>
        The Team
      </Typography>
      <Box display="flex" justifyContent="center">
        <ImageList
          sx={{ width: '100%', maxWidth: 1200 }}
          cols={isSmallScreen ? 1 : isMediumScreen ? 2 : 4} // Adjust columns based on screen size
          gap={16}
        >
          {itemData.map((item) => (
            <ImageListItem key={item.img} sx={{ textAlign: 'left' }}>
              <img
                srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                src={`${item.img}?w=248&fit=crop&auto=format`}
                alt={item.title}
                loading="lazy"
              />
              <ImageListItemBar
                title={item.title}
                subtitle={<span>by: {item.full_name}</span>}
                position="below"
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
    </Box>
  );
}

