import React, { useEffect, useState, useRef } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import axios from 'axios';
import {
  Container, Grid, CardMedia, Typography, Box, Avatar, CircularProgress,
  Button, LinearProgress, List, ListItem, SvgIcon, IconButton, Paper, Fab, Stack, Tooltip, Badge
} from '@mui/material';
import FacebookIcon from "@mui/icons-material/Facebook"; 
import LinkedInIcon from "@mui/icons-material/LinkedIn"; 
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import { styled } from '@mui/material/styles';
import Header from '../components/Header';
import Footer from '../components/Footer';
import MainNav from '../components/MainNav';
import RecipeBlock from '../components/RecipeBlock';
import ProsConsBlock from '../components/ProsConsBlock';
import ProductReferenceBlock from '../components/ProductReferenceBlock';
import '../css/PostView.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebookF,
  faTwitter,
  faLinkedinIn,
  faInstagram,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import { People, Visibility } from '@mui/icons-material'
import AspectRatio from '@mui/joy/AspectRatio';
import Link from '@mui/joy/Link';
import CardContent from '@mui/joy/CardContent';
import Chip from '@mui/joy/Chip';
import Card from '@mui/joy/Card'
import CardCover from '@mui/joy/CardCover';
import 'react-multi-carousel/lib/styles.css';
import CommentSection from '../components/CommentSection';
import { ToastContainer, toast } from 'react-toastify';
import MetaTags from '../components/MetaTags';
import { createTheme, ThemeProvider, useTheme, useMediaQuery } from '@mui/material';
const PostView = () => {
  const printRef = useRef();
  const postpageUrl = window.location.href;
  const { posturl } = useParams();
  const [pagecontent, setPageContent] = useState('');
  const [page_post_id, setPagePostId] = useState(0);
  const [post, setPost] = useState(null);
  const [loaderState, setLoaderState] = useState(true);
  const [navlist, setNavlist] = useState(null);
  const [mainNavlist, setMainNavlist] = useState(null);
  const [pageFooter, setPageFooter] = useState(null);
  const [authorImg, setAuthorImg] = useState("");
  const [authorName, setAuthorName] = useState("");
  const [postImage, setPostImage] = useState("");
  const [followers, setFollowers] = useState("0");
  const [views, setViews] = useState("0");
  const [socialMedia, setSocialMedia] = useState({});
  const [theReviews, setTheReviews] = useState([]);
  const [reviewBreakdown, setReviewBreakdown] = useState([]);
  const [recipes, setRecipes] = useState([]);
  const [prosCons, setProsCons] = useState([]);
  const [productReference, setProductReference] = useState([]);
  const [trendingPosts, setTrendingPosts] = useState([]);
  const [todayNews, setTodayNews] = useState([]);
  const [relatedPosts, setRelatedPosts] = useState([]);
  const [postAddedOn, setPostAddedOn] = useState("");
  const [galleryImages, setGalleryImages] = useState([]); 
  const [galleryContent, setGalleryContent] = useState(''); 

  const [postcategory, setPostCategory] = useState("");
  const [postsubcategory, setPostSubCategory] = useState("");
  const [postcity, setPostCity] = useState("");
  const [postinnercategory, setPostinnerCategory] = useState("");
  const [pagemetatags, setPageMetaTags] = useState([]);
  const [similararticles, setSimilarArticles] = useState("");
  const [postcategoryurl, setPostCategoryurl] = useState("");
  const [postsubcategoryurl, setPostUubCategoryUrl] = useState("");
  const [postcityurl, setPostCityUrl] = useState("");
  const [postinnercategoryurl, setPostinnerCategoryUrl] = useState("");
  const [current_post_category_id, setCurrentPostCategoryid] = useState(0);
  const [postsavestatus, setPostSaveStatus] = useState(0);
  const [userpostcomment, setUserPostComment] = useState("");
  const [postcomments, setPostComments] = useState("");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const handleWriteComment = (event) => {
        setUserPostComment(event.target.value);
  };
  const commentPost = async () => {
    try {
              const userData = localStorage.getItem('user');
              var session_bdo_id = '';
              if (userData) {
                  const { username, expiresAt, customlog } = JSON.parse(userData);
                  const currentTime = new Date().getTime();
                  const expirationTime = new Date(expiresAt).getTime();
                  if (currentTime < expirationTime) {
                  var session_bdo_id = customlog;
                  }
              }
            if (session_bdo_id != "") {
                var response = await axios.post('https://broadwayoriginals.com/apis/index.php', {postcomment: userpostcomment, post_id: page_post_id, user_key: session_bdo_id});
                if(response.data.status == "1"){
                  setPostComments(response.data.data);
                }
            }else{
                toast.error("Please log in to comment on this post.");
            }
            
      } catch (error) {
        
      }
};
  const savePost = async () => {
    try {
            const userData = localStorage.getItem('user');
            var session_bdo_id = '';
            if (userData) {
                const { username, expiresAt, customlog } = JSON.parse(userData);
                const currentTime = new Date().getTime();
                const expirationTime = new Date(expiresAt).getTime();
                if (currentTime < expirationTime) {
                 var session_bdo_id = customlog;
                }
            }
            if (session_bdo_id != "") {
                var response = await axios.post('https://broadwayoriginals.com/apis/index.php', {savepost: "", post_id: page_post_id, user_key: session_bdo_id, category_id: current_post_category_id});
                var response_data = JSON.parse(response.data);
                if(response_data.status == "1")
                {
                    setPostSaveStatus(1);
                    toast.error(response_data.msg);
                }else{
                    toast.error(response_data.msg);
                }
            }else{
                toast.error("Please log in to save this post.");
            }
            
      } catch (error) {
        
      }
};

  // Styled wrapper for the main content area
  const ContentArea = styled(Box)(({ theme }) => ({
    padding: theme.spacing(3),
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[3],
    transition: 'transform 0.2s ease-in-out',
    '&:hover': {
      transform: 'scale(1.02)',
    },
  }));

  // Styled ListItem to add consistent padding and spacing
  const MetaListItem = styled(ListItem)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.text.secondary,
    padding: theme.spacing(0.5),
    '&:not(:last-child)': {
      marginRight: theme.spacing(2),
    },
  }));

  // Styled IconButton for hover effects on social icons
  const SocialIconButton = styled(IconButton)(({ theme }) => ({
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.dark,
    },
  }));
  const handlePrint = () => {
    const printContent = printRef.current;
    const printWindow = window.open('', '', 'width=800,height=600');

    // Get all CSS styles from the current page
    const styles = Array.from(document.styleSheets)
      .map((styleSheet) => {
        try {
          return Array.from(styleSheet.cssRules)
            .map((rule) => rule.cssText)
            .join('');
        } catch (e) {
          console.error(e);
          return '';
        }
      })
      .join('');

    printWindow.document.write(`
      <html>
        <head>
          <title>Print Recipe</title>
          <style>${styles}</style>
        </head>
        <body>
          ${printContent.innerHTML}
        </body>
      </html>
    `);

    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
  };
  const handleBuy = (link) => {
    window.open(link, '_blank');
  };
  const openCustomUrl = (link) => {
    window.location = link;
  };
  const convertGallery = (content) => {
    const tempElement = document.createElement('div');
    tempElement.innerHTML = content;

    const galleryElements = tempElement.querySelectorAll('.bdo-custom-gallery img');
    const images = Array.from(galleryElements).map((img) => img.src); // Extract image URLs

    setGalleryImages(images); // Store images in state for rendering Gallery

    // Replace the original gallery block with the React Gallery component placeholder
    const galleryBlock = tempElement.querySelector('.bdo-custom-gallery');
    if (galleryBlock) {
      galleryBlock.innerHTML = '<div id="react-gallery"></div>'; // Placeholder for React Gallery
    }

    return tempElement.innerHTML;
  };

  const fetchPostData = async () => {
    try {
      const instial_userData = localStorage.getItem('user');
      var session_bdo_id1 = '';
      if (instial_userData) {
          const { username, expiresAt, customlog } = JSON.parse(instial_userData);
          const currentTime1 = new Date().getTime();
          const expirationTime1 = new Date(expiresAt).getTime();
          if (currentTime1 < expirationTime1) {
            var session_bdo_id1 = customlog;
          }
       }
      const response = await axios.get(`https://broadwayoriginals.com/apis/index.php?post_url=${posturl}&user_key=${session_bdo_id1}`);
      const postData = response.data.pagedata;

      document.title = "Broadway Originals | " + postData.post_title;
      setPost(postData);
      let modifiedContent = response.data.pagedata.post_content;
      modifiedContent = convertGallery(modifiedContent);
      setPageContent(modifiedContent);
      setAuthorImg(postData.author_img ? `https://broadwayoriginals.com/${postData.author_img}` : "");
      setAuthorName(postData.author_name || "Unknown Author");
      setPostImage(postData.post_image ? `https://broadwayoriginals.com/${postData.post_image}` : "");
      setFollowers(postData.post_followers || "0");
      setViews(postData.post_views || "0");
      setSocialMedia({
        facebook: postData.facebook || '',
        twitter: postData.twitter || '',
        instagram: postData.instagram || '',
        linkedin: postData.linkedin || '',
        youtube: postData.youtube || '',
      });
      setPagePostId(postData.post_id);
      if(postData.saved_status == "1"){
        setPostSaveStatus(1);
      }else{
        setPostSaveStatus(0);
      }
      setPageMetaTags(response.data.pagedata.meta_data);
      setPostComments(postData.post_comments); 
      setTheReviews(postData.the_reviews || []);
      setReviewBreakdown(postData.review_breakdown || []);
      setRecipes(postData.recipes || []);
      setProductReference(postData.product_reference || []);
      setProsCons(postData.pros_cons || []);
      setTrendingPosts(response.data.trending || []);
      setTodayNews(response.data.todaynews || []);
      setRelatedPosts(postData.related_posts || []);
      setPostAddedOn(postData.post_added_on);
      setPostCategory(response.data.pagedata.category);
      setCurrentPostCategoryid(response.data.pagedata.category.id);
      setPostSubCategory(response.data.pagedata.sub_category); 
      setPostCity(response.data.pagedata.city_id);
      setPostinnerCategory(response.data.pagedata.inner_category); 
      setSimilarArticles(postData.similar_post_url); 
      setPostCategoryurl(postData.post_category_url); 
      setPostCityUrl(postData.post_city_url);
      setPostUubCategoryUrl(postData.post_sub_category_url); 
      setPostinnerCategoryUrl(postData.post_inner_category_url);
      setNavlist(<Header navlist={JSON.stringify(response.data.navlist)} socailmedia={JSON.stringify(response.data.socailmedia)} />);
      setMainNavlist(<MainNav navlist={JSON.stringify(response.data.navlist)} />);
      setPageFooter(<Footer nav_list={JSON.stringify(response.data.navlist)} socailmedia={JSON.stringify(response.data.socailmedia)} />);
      setLoaderState(false);
    } catch (error) {
      console.error('Error fetching post data:', error);
      setLoaderState(false);
    }
  };

  useEffect(() => {
    fetchPostData();
  }, [posturl]);

  if (loaderState) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '50vh' }}>
        <CircularProgress />
      </Box>
    );
  }
  
  return (
    <Container maxWidth={false} sx={{ padding: '0px !important', backgroundColor: '#f5f5f5', minHeight: '100vh' }}>
      <ToastContainer />
      
      {navlist}
      {mainNavlist}
      <MetaTags tags={pagemetatags} />
      <Container sx={{ mt: 5 }}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
          <Stack direction="row" spacing={2} sx={{mb: 2}}>
            {/* Similar Articles Button */}
            <Badge
              sx={{
                backgroundColor: 'none',
                color: '#131313',
                borderRadius: '27px',
                padding: '6px 12px',
                fontFamily: 'Roboto',
                fontSize: '14px',
                fontWeight: '400',
                cursor: 'pointer',
                border: '1px solid'
              }}
              onClick={() => openCustomUrl(similararticles)}
            >
              Similar Articles
            </Badge>

            {/* Post Category Badge */}
            {postcategory.name && postcategory.name !== '[]' && postcategory.name !== 'undefined' && (
              <Badge
                sx={{
                  backgroundColor: 'none',
                  color: '#131313',
                  borderRadius: '27px',
                  padding: '6px 12px',
                  fontFamily: 'Roboto',
                  fontSize: '14px',
                  fontWeight: '400',
                  cursor: 'pointer',
                  border: '1px solid'
                }}
                onClick={() => openCustomUrl(postcategoryurl)}
              >
                {postcategory.name} 
              </Badge>
            )}

          {/* Post City Badge */}
          {postcity.name && postcity.name !== '[]' && postcity.name !== 'undefined' && (
            <Badge
              sx={{
                backgroundColor: 'none',
                color: '#131313',
                borderRadius: '27px',
                padding: '6px 12px',
                fontFamily: 'Roboto',
                fontSize: '14px',
                fontWeight: '400',
                cursor: 'pointer',
                border: '1px solid'
              }}
              onClick={() => openCustomUrl(postcityurl)}
            >
              {postcity.name}
            </Badge>
          )}

        {/* Post Subcategory Badge */}
        {postsubcategory.name && postsubcategory.name !== '[]' && postsubcategory.name !== 'undefined' && (
          <Badge
            sx={{
              backgroundColor: 'none',
              color: '#131313',
              borderRadius: '27px',
              padding: '6px 12px',
              fontFamily: 'Roboto',
              fontSize: '14px',
              fontWeight: '400',
              cursor: 'pointer',
              border: '1px solid'
            }}
            onClick={() => openCustomUrl(postsubcategoryurl)}
          >
            {postsubcategory.name}
          </Badge>
          
        )}

        {/* Post Inner Category Badge */}
        {postinnercategory.name && postinnercategory.name !== '[]' && postinnercategory.name !== 'undefined' && (
          <Badge
            sx={{
              backgroundColor: 'lightgray',
              color: '#131313',
              borderRadius: '27px',
              padding: '6px 12px',
              fontFamily: 'Roboto',
              fontSize: '14px',
              fontWeight: '400',
              cursor: 'pointer',
            }}
            onClick={() => openCustomUrl(postinnercategoryurl)}
          >
            {postinnercategory.name}
          </Badge>
       )}
      </Stack>
            <Card sx={{ borderRadius: 2, background: 'none', boxShadow: 3 }}>
              <CardMedia
                component="img"
                height="400"
                image={postImage}
                alt={post.post_title}
                sx={{ objectFit: 'cover', borderRadius: 2 }}
              />
              <Box
                display="flex"
                flexDirection={isMobile ? 'column' : 'row'}
                alignItems="center"
                justifyContent="space-between"
                sx={{ p: isMobile ? 2 : 3 }}
              >
                {/* Author Info Section */}
                <Box display="flex" alignItems="center" mb={isMobile ? 2 : 0}>
                  <Avatar src={authorImg} alt="Author" sx={{ width: 40, height: 40, mr: 1 }} />
                  <Box sx={{ lineHeight: '1' }}>
                    <Typography variant="body2" fontWeight="bold" sx={{ fontSize: '20px', pt: 1 }}>
                      {authorName}
                    </Typography>
                    <Typography variant="caption" color="text.secondary">
                      Posted on {postAddedOn}
                    </Typography>
                  </Box>
                </Box>

                {/* Social Media & Actions Section */}
                <Stack
                  direction="row"
                  spacing={isMobile ? 0.5 : 1}
                  alignItems="center"
                  flexWrap={isMobile ? 'wrap' : 'nowrap'}
                  justifyContent={isMobile ? 'center' : 'flex-start'}
                >
                  <Typography variant="body2" fontWeight="bold">Share:</Typography>

                  {/* Social Media Buttons */}
                  <Tooltip title="Share on Facebook">
                    <IconButton
                      size="small"
                      color="primary"
                      sx={{ color: 'black', '&:hover': { color: '#00a8a8', transform: 'scale(1.1)' }, transition: 'all 0.3s' }}
                      onClick={() => window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(postpageUrl)}`, '_blank')}
                    >
                      <FacebookIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Share on LinkedIn">
                    <IconButton
                      size="small"
                      color="primary"
                      sx={{ color: 'black', '&:hover': { color: '#00a8a8', transform: 'scale(1.1)' }, transition: 'all 0.3s' }}
                      onClick={() => window.open(`https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(postpageUrl)}`, '_blank')}
                    >
                      <LinkedInIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Share on Twitter">
                    <IconButton
                      size="small"
                      color="primary"
                      sx={{ color: 'black', '&:hover': { color: '#00a8a8', transform: 'scale(1.1)' }, transition: 'all 0.3s' }}
                      onClick={() => window.open(`https://twitter.com/intent/tweet?url=${encodeURIComponent(postpageUrl)}`, '_blank')}
                    >
                      <TwitterIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Instagram (Profile link)">
                    <IconButton
                      size="small"
                      color="primary"
                      sx={{ color: 'black', '&:hover': { color: '#00a8a8', transform: 'scale(1.1)' }, transition: 'all 0.3s' }}
                      onClick={() => window.open('https://www.instagram.com', '_blank')}
                    >
                      <InstagramIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>

                  {/* Copy Link Button */}
                  <Tooltip title="Copy link">
                    <IconButton
                      size="small"
                      color="primary"
                      sx={{ color: 'black', '&:hover': { color: '#00a8a8', transform: 'scale(1.1)' }, transition: 'all 0.3s' }}
                      onClick={() => {
                        navigator.clipboard.writeText(postpageUrl);
                        alert('Link copied to clipboard!');
                      }}
                    >
                      <ContentCopyIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>

                  {/* Save Post Button */}
                  <Tooltip title={postsavestatus === 1 ? "Post already saved" : "Save"}>
                    <Fab
                      size="small"
                      color="secondary"
                      aria-label="add"
                      sx={{
                        backgroundColor: '#00a8a8',
                        '&:hover': { backgroundColor: '#007575' },
                        cursor: postsavestatus === 1 ? 'not-allowed' : 'pointer',
                        mt: isMobile ? 1 : 0 // Add top margin on mobile for spacing
                      }}
                      onClick={postsavestatus !== 1 ? savePost : null}
                      disabled={postsavestatus === 1}
                    >
                      <BookmarkIcon fontSize="small" />
                    </Fab>
                  </Tooltip>
                </Stack>
              </Box>
            </Card>

            
          </Grid>
          {/* Main Post Content */}
          <Grid item xs={12} md={9}>
           <Card sx={{background: 'none'}}>
              <Box sx={{ padding: 2 }}>
                <Typography variant="body1" dangerouslySetInnerHTML={{ __html: pagecontent }}  />           
              </Box>
            </Card>
            <CommentSection userpostcomment={userpostcomment} handleWriteComment={handleWriteComment} commentPost={commentPost} postcomments={postcomments}></CommentSection>
            {/* Reviews */}
            {theReviews.length > 0 && (
              <Card variant="outlined" sx={{ mt: 2, background: 'none' }}>
                <Box > {/* Padding for the card content */}
                  <Typography variant="h5" >Reviews</Typography>
                  {theReviews.map((review) => (
                    <Card
                      key={review.review_title}
                      variant="outlined"
                      sx={{
                        width: '100%',
                        background: 'none',
                        border: 'none',
                        mt: 2,
                        '&:hover': { boxShadow: 'md', borderColor: 'neutral.outlinedHoverBorder' },
                        display: 'flex', // Align items horizontally
                        flexDirection: 'row', // Horizontal layout
                      }}
                    >
                      <Box
                        sx={{
                          width: 90,
                          height: 90,
                          display: 'flex',
                          flexDirection: 'column', // Arrange text vertically
                          alignItems: 'center',
                          justifyContent: 'center',
                          backgroundColor: 'rgb(0, 168, 170)', // Background color
                          borderRadius: 1,
                        }}
                      >
                        <Typography variant="h6" color="white">
                          {review.review_percentage}%
                        </Typography>
                        <Typography variant="body2" color="white"> {/* Add score text below percentage */}
                          Score
                        </Typography>
                      </Box>
                      <CardContent sx={{ flex: 1 }}> {/* Flex property to fill remaining space */}
                        <Typography
                          level="title-lg"
                          id="card-description"
                          sx={{ fontWeight: 'bold', fontSize: '1.3rem' }}
                        >
                          {review.review_title}
                        </Typography>
                        <Typography level="body-sm" aria-describedby="card-description" sx={{ mb: 1 }}>
                          {review.description}
                        </Typography>
                      </CardContent>
                    </Card>
                  ))}
                </Box>
              </Card>
            )}


            {/* Review Breakdown */}
            {reviewBreakdown.length > 0 && (
              <Card sx={{ mt: 4, p: 2, background: 'none' }}>
                <CardContent>
                  <Typography variant="h5" sx={{mb: 3}}>Review Breakdown</Typography>
                  {reviewBreakdown.map((breakdown) => (
                    <Box key={breakdown.topic} sx={{ mb: 2 }}>
                      <Typography>{breakdown.topic}</Typography>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <LinearProgress
                          variant="determinate"
                          value={parseFloat(breakdown.percentage)}
                          sx={{
                            flexGrow: 1,
                            height: 10,
                            borderRadius: 10,
                            backgroundColor: '#e0e0e0',
                            '& .MuiLinearProgress-bar': {
                              backgroundColor: 'rgba(0, 168, 170)',
                              borderRadius: 10,
                            },
                          }}
                        />
                        <Typography>{breakdown.percentage}%</Typography>
                      </Box>
                    </Box>
                  ))}
                </CardContent>
              </Card>
            )}


            {/* Recipes */}
            {recipes.length > 0 && (
              <Box sx={{ mt: 4 }}>
                <RecipeBlock recipes={recipes} handlePrint={handlePrint} printRef={printRef} />
              </Box>
            )}

            {/* Pros and Cons */}
            {prosCons.length > 0 && (
              <Box sx={{ mt: 4 }}>
                <ProsConsBlock pros_cons={prosCons} />
              </Box>
            )}

            {/* Product References */}
            {productReference.length > 0 && (
              <Box sx={{ mt: 4 }}>
                <ProductReferenceBlock product_reference={productReference} handleBuy={handleBuy} />
              </Box>
            )}
            {/* relatedPosts */}
            {relatedPosts.length > 0 && (
            <Grid container spacing={3} sx={{mb: 3}}>
              <Grid item xs={12} sm={6} md={12} lg={12} >
                <Typography variant="h5" sx={{mb: 0}}>Related Posts</Typography>
              </Grid>
              
              {relatedPosts.map((relatedPost) => (
                        <Grid item xs={12} sm={6} md={4} lg={4} key={relatedPost.post_id}>
                          <Card
                            sx={{
                              height: '100%',
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'space-between',
                              transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                              '&:hover': {
                                transform: 'scale(1.05)',
                                boxShadow: '0 12px 20px rgba(0, 0, 0, 0.3)',
                              },
                            }}
                          >
                            <CardMedia
                              component="img"
                              height="180"
                              image={`https://broadwayoriginals.com/${relatedPost.post_img}`}
                              alt={relatedPost.post_title}
                              sx={{ objectFit: 'cover' }}
                            />
                            <Box sx={{flexGrow: 1 }}>
                              <NavLink to={`/${relatedPost.post_custom_url}`} style={{ textDecoration: 'none' }}>
                                <Typography
                                  variant="h6"
                                  sx={{
                                    fontWeight: 'bold',
                                    marginBottom: 1,
                                    color: 'black',
                                    overflow: 'hidden',
                                    display: '-webkit-box',
                                    WebkitBoxOrient: 'vertical',
                                    WebkitLineClamp: 3, // Limit to 3 lines
                                  }}
                                >
                                  {relatedPost.post_title}
                                </Typography>
                              </NavLink>
                            </Box>
                          </Card> 
                        </Grid>
               ))}
            </Grid>
            )}
            
          </Grid>

          {/* Author Information */}
          <Grid item xs={12} md={3}>
            {authorImg && (
              <Card sx={{ padding: 3, textAlign: 'center', mt: 4, boxShadow: 2, borderRadius: 2, marginTop: '0px', background: 'none' }}>
              <Typography variant="h6" sx={{ mb: 2 }}>
                Article by
              </Typography>
              <CardMedia
                component="img"
                height="140"
                image={authorImg}
                alt={authorName}
                sx={{
                  borderRadius: '50%',
                  margin: '0 auto',
                  width: '100px',
                  height: '100px',
                  boxShadow: 3,
                }}
              />
              <Box sx={{ mt: 2 }}>
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                  {authorName}
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 1 }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', mx: 2 }}>
                    <People sx={{ mr: 0.5 }} />
                    <Typography variant="body2" color="text.secondary">
                      {followers}
                    </Typography>
                  </Box>
                  <span>|</span>
                  <Box sx={{ display: 'flex', alignItems: 'center', mx: 2 }}>
                    <Visibility sx={{ mr: 0.5 }} />
                    <Typography variant="body2" color="text.secondary">
                      {views}
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ mt: 2 }}>
                  <Box sx={{ display: 'flex', justifyContent: 'center', mt: 1 }}>
                    {socialMedia.facebook && (
                        <Avatar sx={{background: '#00a8a9', mr: 1, fontSize: '15px', width: '34px', height: '34px'}}>
                            <FontAwesomeIcon
                              icon={faFacebookF}
                            />
                        </Avatar>
                    )}
                    {socialMedia.twitter && (
                      <Avatar sx={{background: '#00a8a9', mr: 1, fontSize: '15px', width: '34px', height: '34px'}}>
                        <FontAwesomeIcon
                          icon={faTwitter}
                        />
                      </Avatar>
                    )}
                    {socialMedia.instagram && (
                      <Avatar sx={{background: '#00a8a9', mr: 1, fontSize: '15px', width: '34px', height: '34px'}}>
                        <FontAwesomeIcon
                          icon={faInstagram}
                        />
                      </Avatar>
                    )}
                    {socialMedia.linkedin && (
                        <Avatar sx={{background: '#00a8a9', mr: 1, fontSize: '15px', width: '34px', height: '34px'}}>
                          <FontAwesomeIcon
                            icon={faLinkedinIn}
                          />
                        </Avatar>
                    )}
                    {socialMedia.youtube && (
                      <Avatar sx={{background: '#00a8a9', mr: 1, fontSize: '15px', width: '34px', height: '34px'}}>
                        <FontAwesomeIcon
                          icon={faYoutube}
                        />
                      </Avatar>
                    )}
                  </Box>
                </Box>
              </Box>
            </Card>          
            )}
            {/* Hot Categories */}
            <Card sx={{ padding: 2, mt: 4, background: 'none' }}>
              <Typography variant="h6" sx={{ marginBottom: '10px', fontWeight: 'bold' }}>
                Hot Categories
              </Typography>
              <Grid container>
                <Card component="li" sx={{ minWidth: '100%', flexGrow: 1, mb: 2 }}>
                  <CardCover>
                    <img
                      src="https://broadwayoriginals.com/static/media/movies.9d3f75aaf05ec666596b.png"
                      srcSet="https://broadwayoriginals.com/static/media/movies.9d3f75aaf05ec666596b.png"
                      loading="lazy"
                      alt=""
                      style={{ width: '100%' }}
                    />
                  </CardCover>
                  <CardContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%' }}>
                    <Typography
                      level="body-lg"
                      textColor="#ffffff"
                      sx={{ fontWeight: 'bold', fontSize: '20px', color: '#ffffff', mt: 0 }}
                    >
                      <a href="/entertainment" style={{ color: 'inherit', textDecoration: 'none' }}>Entertainment</a>
                    </Typography>
                  </CardContent>
                </Card>
                <Card component="li" sx={{ minWidth: '100%', flexGrow: 1, mb: 2 }}>
                  <CardCover>
                    <img
                      src="https://broadwayoriginals.com/static/media/koreya.702c67442116a30c3012.png"
                      srcSet="https://broadwayoriginals.com/static/media/koreya.702c67442116a30c3012.png"
                      loading="lazy"
                      alt=""
                      style={{ width: '100%' }}
                    />
                  </CardCover>
                  <CardContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%' }}>
                    <Typography
                      level="body-lg"
                      textColor="#ffffff"
                      sx={{ fontWeight: 'bold', fontSize: '20px', color: '#ffffff', mt: 0 }}
                    >
                      <a href="/life-style" style={{ color: 'inherit', textDecoration: 'none' }}>Life Style</a>
                    </Typography>
                  </CardContent>
                </Card>
                <Card component="li" sx={{ minWidth: '100%', flexGrow: 1, mb: 2 }}>
                  <CardCover>
                    <img
                      src="https://broadwayoriginals.com/static/media/tech.bfe01c4ec569fdfc7c0d.png"
                      srcSet="https://broadwayoriginals.com/static/media/tech.bfe01c4ec569fdfc7c0d.png"
                      loading="lazy"
                      alt=""
                      style={{ width: '100%' }}
                    />
                  </CardCover>
                  <CardContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%' }}>
                    <Typography
                      level="body-lg"
                      textColor="#ffffff"
                      sx={{ fontWeight: 'bold', fontSize: '20px', color: '#ffffff', mt: 0 }}
                    >
                      <a href="/technology" style={{ color: 'inherit', textDecoration: 'none' }}>Tech</a>
                    </Typography>
                  </CardContent>
                </Card>
                <Card component="li" sx={{ minWidth: '100%', flexGrow: 1 }}>
                  <CardCover>
                    <img
                      src="https://broadwayoriginals.com/static/media/videos.47205ac06599366e4476.png"
                      srcSet="https://broadwayoriginals.com/static/media/videos.47205ac06599366e4476.png"
                      loading="lazy"
                      alt=""
                      style={{ width: '100%' }}
                    />
                  </CardCover>
                  <CardContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%' }}>
                    <Typography
                      level="body-lg"
                      textColor="#ffffff"
                      sx={{ fontWeight: 'bold', fontSize: '20px', color: '#ffffff', mt: 0 }}
                    >
                      <a href="/videos" style={{ color: 'inherit', textDecoration: 'none' }}>Videos</a>
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Card>


            {/* Trending Posts */}
            {trendingPosts.length > 0 && (
              <Card sx={{ padding: 2, mt: 4, background: 'none'}}>
                <Typography variant="h6" sx={{ marginBottom: '10px', fontWeight: 'bold' }}>
                  Trending
                </Typography>
                <Grid container spacing={2}>
                  {trendingPosts.map((trendingPost) => (
                    <Grid item xs={12} sm={12} md={12} key={trendingPost.post_id}>
                      <Card
                        variant="outlined"
                        orientation="horizontal"
                        sx={{
                          border: 'none',
                          display: 'flex',
                          background: 'none',
                          width: '100%',
                          '&:hover': { boxShadow: 'md', borderColor: 'neutral.outlinedHoverBorder' }, padding: '0px'
                        }}
                      >
                        <AspectRatio ratio="1" sx={{ width: 90 }}>
                          <img
                            src={`https://broadwayoriginals.com/${trendingPost.post_img}`}
                            loading="lazy"
                            alt={trendingPost.post_title}
                            style={{ borderRadius: '6px' }} // For rounding corners of the image
                          />
                        </AspectRatio>
                        <CardContent>
                        <Link href={`/${trendingPost.custom_url}`} underline="hover" color="inherit" sx={{ flexGrow: 1 }}>
                          <Typography
                            level="title-lg"
                            id="card-description"
                            fontWeight="bold"
                            sx={{
                              display: '-webkit-box',
                              WebkitBoxOrient: 'vertical',
                              overflow: 'hidden',
                              WebkitLineClamp: 4, // Limit to 4 lines
                              textOverflow: 'ellipsis',
                            }}
                          >
                            {trendingPost.post_title}
                          </Typography>
                        </Link>
                        </CardContent>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              </Card>
            )}
          {/* Today's News */}
          {todayNews.length > 0 && (
              <Card sx={{ padding: 2, mt: 4, background: 'none', }}>
                <Typography variant="h6" sx={{ marginBottom: '10px', fontWeight: 'bold' }}>
                  Today's News
                </Typography>
                <Grid container spacing={2}>
                  {todayNews.map((newsPost) => (
                    <Grid item xs={12} sm={12} md={12} key={newsPost.post_id}>
                        <Card
                            sx={{
                              border: 'none',
                              background: '',
                              p: 0,
                              height: 'auto',
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'space-between',
                              transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                              '&:hover': {
                                transform: 'scale(1.05)',
                                boxShadow: '0 12px 20px rgba(0, 0, 0, 0.3)',
                              },
                            }}
                          >
                            <CardMedia
                              component="img"
                              height="180"
                              image={`https://broadwayoriginals.com/${newsPost.post_img}`}
                              alt={newsPost.post_title}
                              sx={{ objectFit: 'cover' }}
                            />
                            <Box sx={{ padding: 2, flexGrow: 1 }}>
                              <NavLink to={`/${newsPost.custom_url}`} style={{ textDecoration: 'none' }}>
                                <Typography
                                  variant="h6"
                                  sx={{
                                    fontWeight: 'bold',
                                    marginBottom: 1,
                                    color: 'black',
                                    overflow: 'hidden',
                                    display: '-webkit-box',
                                    WebkitBoxOrient: 'vertical',
                                    WebkitLineClamp: 3,
                                    fontSize: '1rem' 
                                  }}
                                >
                                  {newsPost.post_title}
                                </Typography>
                              </NavLink>

                            </Box>
                          </Card>
                    </Grid>
                  ))}
                </Grid>
              </Card>
            )}
          </Grid>
        </Grid>
      </Container>
      {pageFooter}
    </Container>
  );
};

export default PostView;
