import React from 'react';
import { Box, Grid, Typography, Avatar, Container } from '@mui/material';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import cityguides from '../images/city-guide.svg';
import lifestyle from '../images/Lifestyle.svg';
import { useMediaQuery, useTheme } from '@mui/material';

// Background styles
const waveBackgroundStyle = {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  background: 'linear-gradient(135deg, rgba(240, 244, 248, 0.8), rgba(226, 234, 252, 0.8))',
  zIndex: -1,
  overflow: 'hidden',
};

const largeCircleStyle = {
  position: 'absolute',
  top: '15%',
  left: '15%',
  width: '300px', // Larger size
  height: '300px', // Larger size
  borderRadius: '50%',
  backgroundColor: 'rgba(255, 255, 255, 0.3)', // Slightly translucent
  boxShadow: '0 10px 50px rgba(0, 0, 0, 0.1)',
  zIndex: -1,
  animation: 'pulse 10s infinite alternate', // Pulse animation
};

const smallCircleStyle = {
  position: 'absolute',
  top: '60%',
  left: '70%',
  width: '150px',
  height: '150px',
  borderRadius: '50%',
  backgroundColor: 'rgba(61, 170, 170, 0.2)', // Subtle color
  zIndex: -1,
  animation: 'bounce 8s infinite alternate', // Bounce animation
};

const dotBackgroundStyle = {
  position: 'absolute',
  top: '30%',
  left: '10%',
  width: '20px',
  height: '20px',
  borderRadius: '50%',
  backgroundColor: '#3daaaa',
  opacity: 0.2,
  animation: 'dot-float 4s ease-in-out infinite',
};

const dotBackgroundStyle2 = {
  position: 'absolute',
  top: '80%',
  left: '75%',
  width: '30px',
  height: '30px',
  borderRadius: '50%',
  backgroundColor: '#3daaaa',
  opacity: 0.2,
  animation: 'dot-float 5s ease-in-out infinite',
};

// Animation keyframes
const styles = `
  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(1.05);
    }
  }

  @keyframes bounce {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-30px);
    }
    100% {
      transform: translateY(0);
    }
  }
  
  @keyframes dot-float {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-20px);
    }
    100% {
      transform: translateY(0);
    }
  }
`;

const Category = ({ nav_list }) => {
  const nav_list_json = JSON.parse(nav_list);
  
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const onclickOpenurl = (pageurl) => {
    window.location = pageurl;
  };

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // Render grid items for larger screens
  const renderItems = (data) => {
    return data.map((category) => (
      category.data && (category.category_name === 'City Guides' || category.category_name === 'Life Style') && (
        <Grid item xs={12} sm={12} md={6} key={category.category_name} sx={{ mb: 3 }}>
          <Box sx={{ textAlign: 'left', mb: 5, transition: 'transform 0.5s', '&:hover': { transform: 'scale(1.05)' } }}>
            <Typography variant="h5" sx={{ alignItems: "center", display: "flex", fontWeight: "bold", textAlign: 'left' }}>
              <Avatar
                src={category.category_name === 'City Guides' ? cityguides : lifestyle}
                alt={category.category_name}
                sx={{
                  display: 'inline-block',
                  mr: 1,
                  borderRadius: "0px",
                  transition: 'transform 1s',
                  transformStyle: 'preserve-3d',
                  '&:hover': {
                    transform: 'rotateY(360deg)'
                  }
                }}
              />
              {category.category_name}
            </Typography>
          </Box>
          {category.category_name === 'City Guides' && (
            <Grid container spacing={2}>
              {category.data.cities.map((city) => (
                <Grid item xs={6} sm={3} key={city.name}>
                  <Box sx={{ textAlign: 'center' }}>
                    <Avatar
                      src={`https://broadwayoriginals.com/${city.image}`}
                      alt={city.name}
                      sx={{
                        width: '80%',
                        height: 'auto',
                        border: '1px solid #3daaaa',
                        margin: 'auto',
                        transition: 'transform 1s',
                        transformStyle: 'preserve-3d',
                        '&:hover': {
                          transform: 'rotateY(360deg)'
                        }
                      }}
                    />
                    <Typography sx={{ mt: 1 }} onClick={() => onclickOpenurl(`/${category.custom_url}/${city.urlstring}`)} style={{ cursor: 'pointer' }}>
                      {city.name}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          )}
          {category.category_name === 'Life Style' && (
            <Grid container spacing={2}>
              {category.data.sub_categories.map((sub_category) => (
                <Grid item xs={6} sm={3} key={sub_category.name}>
                  <Box sx={{ textAlign: 'center' }}>
                    <Avatar
                      src={`https://broadwayoriginals.com/${sub_category.img}`}
                      alt={sub_category.name}
                      sx={{
                        width: '80%',
                        height: 'auto',
                        border: '1px solid #3daaaa',
                        margin: 'auto',
                        transition: 'transform 1s',
                        transformStyle: 'preserve-3d',
                        '&:hover': {
                          transform: 'rotateY(360deg)'
                        }
                      }}
                    />
                    <Typography sx={{ mt: 1 }} onClick={() => onclickOpenurl(`/${category.custom_url}`)} style={{ cursor: 'pointer' }}>
                      {sub_category.name}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          )}
        </Grid>
      )
    ));
  };

  // Render slider for mobile screens
  const renderSliderList = (data) => {
    return data.map((category) => (
      category.data && (category.category_name === 'City Guides' || category.category_name === 'Life Style') && (
        <Grid item xs={12} key={category.category_name} sx={{ mb: 3 }}>
          <Typography variant="h5" sx={{ textAlign: 'left', mb: 2 }}>
            <Avatar src={category.category_name === 'City Guides' ? cityguides : lifestyle} alt={category.category_name} sx={{ display: 'inline-block', mr: 1 }} />
            {category.category_name}
          </Typography>
          {category.category_name === 'City Guides' && (
            <Slider {...sliderSettings}>
              {category.data.cities.map((city) => (
                <Box key={city.name} sx={{ textAlign: 'center' }}>
                  <Avatar
                    src={`https://broadwayoriginals.com/${city.image}`}
                    alt={city.name}
                    sx={{
                      width: '70%',
                      height: 'auto',
                      border: '1px solid #3daaaa',
                      margin: 'auto',
                      transition: 'transform 1s',
                      transformStyle: 'preserve-3d',
                      '&:hover': {
                        transform: 'rotateY(360deg)'
                      }
                    }}
                  />
                  <Typography sx={{ mt: 1 }} onClick={() => onclickOpenurl(`/${category.custom_url}/${city.urlstring}`)} style={{ cursor: 'pointer' }}>
                    {city.name}
                  </Typography>
                </Box>
              ))}
            </Slider>
          )}
          {category.category_name === 'Life Style' && (
            <Slider {...sliderSettings}>
              {category.data.sub_categories.map((sub_category) => (
                <Box key={sub_category.name} sx={{ textAlign: 'center' }}>
                  <Avatar
                    src={`https://broadwayoriginals.com/${sub_category.img}`}
                    alt={sub_category.name}
                    sx={{
                      width: '70%',
                      height: 'auto',
                      border: '1px solid #3daaaa',
                      margin: 'auto',
                      transition: 'transform 1s',
                      transformStyle: 'preserve-3d',
                      '&:hover': {
                        transform: 'rotateY(360deg)'
                      }
                    }}
                  />
                  <Typography sx={{ mt: 1 }} onClick={() => onclickOpenurl(`/${category.custom_url}`)} style={{ cursor: 'pointer' }}>
                    {sub_category.name}
                  </Typography>
                </Box>
              ))}
            </Slider>
          )}
        </Grid>
      )
    ));
  };

  return (
    <>
      <Grid container spacing={{ xs: 2, md: 20, pt: 5 }}>
          {!isMobile && renderItems(nav_list_json)}
          {isMobile && renderSliderList(nav_list_json)}
      </Grid>
    </>
  );
};

export default Category;
