import React from "react";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { useNavigate } from "react-router-dom";

const Info = ({ children, useStyles }) => {
  const classes = useStyles();
  return <div className={classes.info}>{children}</div>;
};

const InfoTitle = ({ children, onClick }) => (
  <Box
    component="h2"
    onClick={onClick}
    sx={{
      margin: 0,
      fontSize: "20px",
      fontWeight: "bold",
      display: "-webkit-box",
      WebkitBoxOrient: "vertical",
      overflow: "hidden",
      WebkitLineClamp: 3,
      textOverflow: "ellipsis",
      height: '4.5em', // Fixed height for title area
      cursor: 'pointer',
      color: '#333', // Default color
      "&:hover": { color: "#00A8AA" }, // Hover color
    }}
  >
    {children}
  </Box>
);

const getInfoN01Styles = () => ({
  info: {
    textAlign: "left",
    padding: "0.5rem",
    color: "#333",
  },
});

export function CardPost({ post }) {
  const post_details = post;
  const navigate = useNavigate();

  const handleTitleClick = () => {
    const postUrl = `/${post_details.custom_url}`;
    navigate(postUrl);
  };

  return (
    <Card
      sx={{
        maxWidth: 304,
        margin: "auto",
        boxShadow: "0 0 20px 0 rgba(0,0,0,0.12)",
        transition: "0.3s",
        overflow: "hidden",
        minHeight: '380px',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <CardMedia
        image={`https://broadwayoriginals.com/${post_details.post_img}`}
        sx={{
          width: "100%",
          paddingBottom: "95.25%",
          clipPath: "polygon(0 0, 100% 0%, 100% 84%, 0% 100%)",
          backgroundColor: "rgba(0, 0, 0, 0.08)",
        }}
      />
      <Avatar
        src={`https://broadwayoriginals.com/${post_details.author.img}`}
        sx={{
          width: 50,
          height: 50,
          border: "2px solid #fff",
          margin: "-48px 32px 0 auto",
          "& > img": {
            margin: 0,
          },
        }}
      />
      <CardContent sx={{ p: 3, flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
        <Info useStyles={getInfoN01Styles}>
          <InfoTitle onClick={handleTitleClick}>
            {post_details.post_title}
          </InfoTitle>
        </Info>
      </CardContent>
    </Card>
  );
}

export default CardPost;
