import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Container,
  Grid,
  Card,
  CardMedia,
  Typography,
  Box,
  Avatar,
  CircularProgress,
  Paper,
  Button,
} from '@mui/material';
import { NavLink, useParams } from 'react-router-dom';
import Header from '../components/Header';
import MainNav from '../components/MainNav';
import Footer from '../components/Footer';
import MetaTags from '../components/MetaTags';
import PaginationBlock from '../components/PaginationBlock';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faUserPlus, faBookmark } from '@fortawesome/free-solid-svg-icons';
const CityGuides = () => {
  const { selectcity, selectsubcategory, innercategory } = useParams();
  const [data, setData] = useState(null);
  const [loaderState, setLoaderState] = useState(true);
  const [navlist, setNavlist] = useState(null);
  const [mainnavlist, setMainNavlist] = useState(null);
  const [pageFooter, setPageFooter] = useState(null);
  const [pageTitle1, setPageTitle1] = useState('');
  const [pageTitle2, setPageTitle2] = useState('');
  const [contentPara, setContentPara] = useState('');
  const [activeFlag, setActiveFlag] = useState('0');
  const [allCities, setAllCities] = useState([]);
  const [total_pages, setTotalPages] = useState(0);
  const [total_records, setTotalRecords] = useState(0);
  const [active_page, setActivePage] = useState(0);
  const [page_content_views, setPageContentView] = useState(0);
  const [pagemetatags, setPageMetaTags] = useState([]);
  const onclickOpenURL = (pageurl) => {
      window.location = "/"+pageurl;
  }
  const fetchPageData = async () => {
    try {
      const response = await axios.post('https://broadwayoriginals.com/apis/index.php', { cityguides: '', subcategory: '' });
      document.title = "Broadway Originals | " + response.data.data.page_title.part1 + " " + response.data.data.page_title.part2;

      setNavlist(
        <Header 
          navlist={JSON.stringify(response.data.navlist)} 
          socailmedia={JSON.stringify(response.data.socailmedia)} 
        />
      );
      setMainNavlist(<MainNav navlist={JSON.stringify(response.data.navlist)} />);
      setPageMetaTags(response.data.data.meta_tags);
      setData(response.data.data.page_data);
      setPageTitle1(response.data.data.page_title.part1);
      setPageTitle2(response.data.data.page_title.part2);
      setContentPara(`Discover a complete guide to ${response.data.topics}.`);
      setAllCities(response.data.allcities || []);
      setPageFooter(<Footer nav_list={JSON.stringify(response.data.navlist)} socailmedia={JSON.stringify(response.data.socailmedia)} />);
      setLoaderState(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoaderState(false);
    }
  };
  const fetchCityUrlPageData = async (city_url) => {    
    try { 
        setLoaderState(true);
        setActiveFlag(city_url);   
        setData(null);
        const response = await axios.post('https://broadwayoriginals.com/apis/index.php', {cityguides: "",cityurl: city_url});
        setData(response.data.data.page_data);
        setAllCities(response.data.allcities);
        setNavlist(
          <Header 
            navlist={JSON.stringify(response.data.navlist)} 
            socailmedia={JSON.stringify(response.data.socailmedia)} 
          />
        );
        setMainNavlist(<MainNav navlist={JSON.stringify(response.data.navlist)} />);
        setPageMetaTags(response.data.data.meta_tags);
        const citynames1= response.data.data.page_data.map(city => city.name).join(', ');
        setPageTitle1(response.data.data.page_title.part1);
        setPageTitle2(response.data.data.page_title.part2);
        document.title = "Broadway Originals | "+response.data.data.page_title.part1+" "+response.data.data.page_title.part2;
        setContentPara('Discover a complete guide to where to '+response.data.topics+' in '+citynames1+'. Find curated reviews & recommendations.');

        setPageFooter(<Footer nav_list={JSON.stringify(response.data.navlist)} socailmedia={JSON.stringify(response.data.socailmedia)} />);
        setLoaderState(false);
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};
const fetchSubCategoryUrlPageData = async (cityid,selectsubcategory) => {    
  try { 
      setLoaderState(true);      
      setActiveFlag(cityid);   
      setData(null);
      const response = await axios.post('https://broadwayoriginals.com/apis/index.php', {cityguides: "", cityurl: cityid, sub_category_url: selectsubcategory});
      setData(response.data.data.page_data);
      setAllCities(response.data.allcities);
      setPageMetaTags(response.data.data.meta_tags);
      setNavlist(
        <Header 
          navlist={JSON.stringify(response.data.navlist)} 
          socailmedia={JSON.stringify(response.data.socailmedia)} 
        />
      );
      setMainNavlist(<MainNav navlist={JSON.stringify(response.data.navlist)} />);
      setPageMetaTags(response.data.data.meta_tags);
      const citynames1= response.data.data.page_data.map(city => city.name).join(', ');
      setPageTitle1(response.data.data.page_title.part1);
      setPageTitle2(response.data.data.page_title.part2);
      document.title = "Broadway Originals | "+response.data.data.page_title.part1+" "+response.data.data.page_title.part2;
      setContentPara('Discover a complete guide to where to '+response.data.topics+' in '+citynames1+'. Find curated reviews & recommendations.');      
      setPageFooter(<Footer nav_list={JSON.stringify(response.data.navlist)} socailmedia={JSON.stringify(response.data.socailmedia)}></Footer>);
      setLoaderState(false);
  } catch (error) {
      console.error('Error fetching data:', error);
  }
};
const fetchInnerCategoryPageData = async (cityid,subcategory_id,inner_category_id) => {    
  try { 
      setLoaderState(true);      
      setActiveFlag(cityid);   
      setData(null);
      const response = await axios.post('https://broadwayoriginals.com/apis/index.php', {cityguides: "", cityurl: cityid, sub_category_url: subcategory_id, innercategory_url: inner_category_id});
      setData(response.data.data.page_data);
      setAllCities(response.data.allcities);
      setNavlist(
        <Header 
          navlist={JSON.stringify(response.data.navlist)} 
          socailmedia={JSON.stringify(response.data.socailmedia)} 
        />
      );
      setMainNavlist(<MainNav navlist={JSON.stringify(response.data.navlist)} />);
      setPageMetaTags(response.data.data.meta_tags);
      setTotalPages(response.data.data.page_data[0].total_pages);
      setTotalRecords(response.data.data.page_data[0].total_records);
      setActivePage(response.data.data.page_data[0].active_page);
      const citynames1= response.data.data.page_data.map(city => city.name).join(', ');
      setPageTitle1(response.data.data.page_title.part1);
      setPageTitle2(response.data.data.page_title.part2);
      document.title = "Broadway Originals | "+response.data.data.page_title.part1+" "+response.data.data.page_title.part2;
      setContentPara('Discover a complete guide to where to '+response.data.topics+' in '+citynames1+'. Find curated reviews & recommendations.');
      setPageFooter(<Footer nav_list={JSON.stringify(response.data.navlist)} socailmedia={JSON.stringify(response.data.socailmedia)}></Footer>);    
      setLoaderState(false);
  } catch (error) {
      console.error('Error fetching data:', error);
  }
};
const fetchPages = async (city_id,sub_category_id,inner_category_id, page_number) => {    
  try { 
      setLoaderState(true);      
      setActiveFlag(city_id);   
      setData(null);
      const response = await axios.post('https://broadwayoriginals.com/apis/index.php', {cityguides: "", cityurl: city_id, sub_category_url: sub_category_id, innercategory_url: inner_category_id, page: page_number});
      setData(response.data.data.page_data);
      setAllCities(response.data.allcities);
      const citynames1= response.data.data.page_data.map(city => city.name).join(', ');
      setPageTitle1(response.data.data.page_title.part1);
      setPageTitle2(response.data.data.page_title.part2);
      setNavlist(
        <Header 
          navlist={JSON.stringify(response.data.navlist)} 
          socailmedia={JSON.stringify(response.data.socailmedia)} 
        />
      );
      setMainNavlist(<MainNav navlist={JSON.stringify(response.data.navlist)} />);
      setPageMetaTags(response.data.data.meta_tags);
      document.title = "Broadway Originals | "+response.data.data.page_title.part1+" "+response.data.data.page_title.part2;
      setTotalPages(response.data.data.page_data[0].total_pages);
      setTotalRecords(response.data.data.page_data[0].total_records);
      setActivePage(response.data.data.page_data[0].active_page);
      setContentPara('Discover a complete guide to where to '+response.data.topics+' in '+citynames1+'. Find curated reviews & recommendations.');
      setPageFooter(<Footer nav_list={JSON.stringify(response.data.navlist)} socailmedia={JSON.stringify(response.data.socailmedia)}></Footer>);
      setLoaderState(false);
  } catch (error) {
      console.error('Error fetching data:', error);
  }
};
  useEffect(() => {
    if(selectcity != null && selectcity != undefined && selectsubcategory != null && selectsubcategory != undefined && innercategory != null && innercategory != undefined){
      fetchInnerCategoryPageData(selectcity, selectsubcategory, innercategory);
    }else if(selectcity != null && selectcity != undefined && selectsubcategory != null && selectsubcategory != undefined){
        fetchSubCategoryUrlPageData(selectcity, selectsubcategory);
    }else if(selectcity != null && selectcity != undefined){
        fetchCityUrlPageData(selectcity);
    }else{
        fetchPageData();
    }
  }, []);

  return (
    <>
      <Container
        maxWidth={false}
        sx={{
          padding: '0px !important',
          background: 'url(https://source.unsplash.com/random/1920x1080)',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          minHeight: '100vh',
          color: '#fff',
        }}
      >
        {navlist}
        {mainnavlist}
        <MetaTags tags={pagemetatags} />
        {loaderState && (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '50vh' }}>
            <CircularProgress />
          </Box>
        )}

        

        <Container maxWidth="lg" sx={{ padding: { xs: '20px', md: '0px' }, marginTop: '40px' }}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Box sx={{ perspective: '1000px', textAlign: 'center' }}>
                <Typography
                  variant="h2"
                  gutterBottom
                  sx={{
                    color: 'rgba(0, 0, 0, 0.87)',
                    fontSize: '2.75rem',
                    
                    display: 'inline-block',
                    transition: 'transform 0.3s ease',
                    transformStyle: 'preserve-3d',
                    '&:hover': {
                      transform: 'rotateY(10deg) rotateX(10deg)',
                    },
                  }}
                >
                  {pageTitle1} {pageTitle2}
                </Typography>
              </Box>
              <Typography
                variant="body1"
                sx={{
                  color: 'rgba(0, 0, 0, 0.87)',
                  textAlign: 'center',
                  marginBottom: '20px',
                  maxWidth: '800px',
                  margin: '0 auto',
                }}
              >
                {contentPara}
              </Typography>
            </Grid>

            {/* City Selection */}
            {allCities.length > 0 ? (
              <Grid item xs={12}>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', mb: 3 }}>
                  {allCities.map((city) => (
                    <Paper
                      key={city.id}
                      elevation={3}
                      sx={{
                        margin: '10px',
                        padding: '10px 20px',
                        background: activeFlag === city.id || activeFlag === city.custom_url ? '#00a8aa' : 'none',
                        color: activeFlag === city.id || activeFlag === city.custom_url ? 'white' : '#333',
                        borderRadius: '20px',
                        cursor: 'pointer',
                        transition: 'all 0.3s',
                        '&:hover': {
                          backgroundColor: '#00a8aa',
                          color: 'white',
                        },
                      }}
                      onClick={() => setActiveFlag(city.id)}
                    >
                      <Typography variant="body1" sx={{ fontWeight: 'bold' }} onClick={() => onclickOpenURL('city-guides/'+city.custom_url)}>
                        {city.name}
                      </Typography>
                    </Paper>
                  ))}
                </Box>
              </Grid>
            ) : (
              <Typography variant="body1" sx={{ textAlign: 'center', marginTop: '20px', color: '#888', width: '100%' }}>
               
              </Typography>
            )}

            {/* Data Rendering - Only show categories with posts */}
            {data && data.length > 0 ? (
              data
                .filter((category) => category.posts.length > 0)              
                .map((category) => (
                  <Grid item xs={12} key={category.id}>
                    <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      marginBottom: '20px',
                    }}
                  >
                    <Typography variant="h5" sx={{ textAlign: 'left', color: '#008889', fontWeight: 'bold', 
                    transition: 'transform 0.3s ease',
                    transformStyle: 'preserve-3d',
                    '&:hover': {
                      transform: 'rotateY(10deg) rotateX(10deg)',
                    }, }}>
                      Explore {category.name}
                    </Typography>

                    {/* Explore More button */}
                    {total_pages == '0' && (
                      <Button
                        variant="contained"
                        color="default"
                        component={NavLink}
                        onClick={() => {
                          const url = selectcity 
                            ? (selectsubcategory 
                                ? `city-guides/${selectcity}/${selectsubcategory}/${category.urlstring}`
                                : `city-guides/${selectcity}/${category.urlstring}`
                              ) 
                            : `city-guides/${category.urlstring}`;
                          
                          onclickOpenURL(url);
                        }}
                        sx={{ 
                          textDecoration: 'none', 
                          color: 'rgb(0 137 138)', 
                          border: '1px solid #008586', 
                          fontWeight: 'bold', 
                          borderRadius: '15px',
                          display: { xs: 'none', md: 'block' },  // Hide on mobile, show on desktop (md and up)
                        }}
                      >
                        Explore More
                      </Button>
                    )}
                  </Box>
                    <Grid container spacing={3}>
                       {category.posts.length > 0 && page_content_views == "0" && setPageContentView(1)}
                      {category.posts.map((post) => (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={post.post_id}>
                         
                          <Card
                            sx={{
                              height: '100%',
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'space-between',
                              transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                              '&:hover': {
                                transform: 'scale(1.05)',
                                boxShadow: '0 12px 20px rgba(0, 0, 0, 0.3)',
                              },
                            }}
                          >
                            <CardMedia
                              component="img"
                              height="180"
                              image={`https://broadwayoriginals.com/${post.post_img}`}
                              alt={post.post_title}
                              sx={{ objectFit: 'cover' }}
                            />
                            <Box sx={{ padding: 2, flexGrow: 1 }}>
                              <NavLink to={`/${post.custom_url}`} style={{ textDecoration: 'none' }}>
                                <Typography
                                  variant="h6"
                                  sx={{
                                    fontWeight: 'bold',
                                    marginBottom: 1,
                                    color: 'black',
                                    overflow: 'hidden',
                                    display: '-webkit-box',
                                    WebkitBoxOrient: 'vertical',
                                    WebkitLineClamp: 3, // Limit to 3 lines
                                  }}
                                >
                                  {post.post_title}
                                </Typography>
                              </NavLink>

                              {/* Post details: views, followers, author */}
                              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '10px' }}>
                                <Box sx={{ display: 'flex', alignItems: 'center', color: 'gray' }}>
                                  <FontAwesomeIcon icon={faEye} style={{ marginRight: '5px' }} />
                                  <Typography variant="body2">{post.post_views || 0} views</Typography>
                                </Box>

                                <Box sx={{ display: 'flex', alignItems: 'center', color: 'gray' }}>
                                  <FontAwesomeIcon icon={faUserPlus} style={{ marginRight: '5px' }} />
                                  <Typography variant="body2">{post.post_followers || 0} followers</Typography>
                                </Box>

                                
                              </Box>

                            
                            </Box>
                          </Card>
                          
                        </Grid>
                      ))}

                    </Grid>
                    {total_pages == '0' && (
                      <Button
                        variant="contained"
                        color="default"
                        component={NavLink}
                        onClick={() => {
                          const url = selectcity
                            ? (selectsubcategory
                                ? `city-guides/${selectcity}/${selectsubcategory}/${category.urlstring}`
                                : `city-guides/${selectcity}/${category.urlstring}`
                              )
                            : `city-guides/${category.urlstring}`;
                          
                          onclickOpenURL(url);
                        }}
                        sx={{ 
                          textDecoration: 'none', 
                          color: 'rgb(0 137 138)', 
                          border: '1px solid #008586', 
                          fontWeight: 'bold', 
                          width: '100%',
                          mt: 2,
                          textAlign: 'center',
                          display: { xs: 'block', sm: 'none' },  
                        }}
                      >
                        Explore More
                      </Button>
                    )}

                  </Grid>
                ))
            ) : (
              <Typography variant="body1" sx={{ textAlign: 'center', marginTop: 3, color: '#888', width: '100%' }}>
                
              </Typography>
            )}
            {page_content_views == "0" && 
              <Typography variant="body1" sx={{ textAlign: 'center', marginTop: 3, color: '#888', width: '100%' }}>
                No data available.
             </Typography>
            }
            {/* Pagination */}
            <Grid item xs={12} sx={{ mb: 3 }}>
            {total_pages > 1 && (             
              <PaginationBlock total_pages={total_pages} active_page={active_page} onPageChange={fetchPages} city_id={selectcity} sub_category_id={selectsubcategory} inner_category_id={innercategory} />
            )}
            </Grid>
           
          </Grid>
        </Container>
      </Container>
      {pageFooter}
    </>
  );
};

export default CityGuides;
