import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import {
  Container,
  Grid,
  Box,
  Typography,
  CircularProgress,
  TextField,
  Button, Avatar, useMediaQuery, useTheme, Link, Divider
} from '@mui/material';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import CheckIcon from '@mui/icons-material/Check';
import Header from '../components/Header';
import MainNav from '../components/MainNav';
import Footer from '../components/Footer';
import MetaTags from '../components/MetaTags';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const Verify = () => {
  const [data, setData] = useState(null);
  const [loaderState, setLoaderState] = useState(true);
  const [navlist, setNavlist] = useState(null);
  const [mainnavlist, setMainNavlist] = useState(null);
  const [pageFooter, setPageFooter] = useState(null);
  const [pagemetatags, setPageMetaTags] = useState([]);
  const [pagecontent, setPageContent] = useState("");
  let { token, mail } = useParams();
  const fetchPageData = async (token, mail) => {
        try {
            if(token!="" && mail!=""){
                const response = await axios.get('https://broadwayoriginals.com/apis/index.php?email_verification=&token='+token+"&mail="+mail);
                setLoaderState(false);
                document.title = "Broadway Originals | Contact Us";
                const responseData = response?.data || {};
                setPageContent(response.data.pagedata);
                setNavlist(
                    <Header
                    navlist={JSON.stringify(responseData.navlist || {})}
                    socailmedia={JSON.stringify(responseData.socailmedia || {})}
                    />
                );
                setMainNavlist(<MainNav navlist={JSON.stringify(responseData.navlist || {})} />);
                setPageFooter(
                    <Footer
                    nav_list={JSON.stringify(responseData.navlist || {})}
                    socailmedia={JSON.stringify(responseData.socailmedia || {})}
                    />
                );
                setPageMetaTags(responseData.pagemetatags || []);
                setLoaderState(false);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
   };
 

  useEffect(() => {
    fetchPageData(token, mail);
  }, []);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <>
      <Container
        maxWidth={false}
        sx={{
          padding: '0px !important',
          background: 'url(https://source.unsplash.com/random/1920x1080)',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          minHeight: '100%',
          color: '#fff',
          
        }}
      >
        <ToastContainer />
        {navlist}
        {mainnavlist}
        <MetaTags tags={pagemetatags} />
        {loaderState && (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '50vh' }}>
            <CircularProgress />
          </Box>
        )}
        {!loaderState && (
          <Container maxWidth="lg" sx={{ padding: { xs: '20px', md: '0px' }, mt: 5, mb: 5, color: 'black', minHeight: '50vh' }}>          
            <Box sx={{ padding: 2 }}>
                {pagecontent!="" && pagecontent.status != 'undefined' && pagecontent.status == "1" &&
                    <Alert severity="success">
                        <AlertTitle>{pagecontent.msg}</AlertTitle>
                        Please login <a href="/signin">Sign In</a>
                  </Alert>
                }
                {pagecontent!="" && pagecontent.status != 'undefined' && pagecontent.status == "0" &&
                     <Alert
                     severity="error"
                     sx={{
                       fontSize: '18px', // Adjusts font size for the alert text
                       '& .MuiAlert-icon': {
                         fontSize: '24px', // Adjusts font size for the icon
                       },
                     }}
                   >
                     {pagecontent.msg}
                   </Alert>
                   
                }
            </Box>
          </Container>
        )}
      </Container>
      {pageFooter}
    </>
  );
};

export default Verify;
